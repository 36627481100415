import React from 'react';
import FeatureCard from './FeatureCard/feature-card';
import Question1 from './Question1/question1';

const Home = (props) => {
  return (
    // <div style={{ backgroundColor: '#E0E8F3', minHeight: '100vh', overflow: 'auto', padding: '20px' }}>
    //   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
    //     <div style={{ width: '100%', display: 'flex', marginTop: '96px', justifyContent: 'center', padding: '20px' }}>
    //       <div style={{ maxWidth: '800px', textAlign: 'center' }}>
    //         <h1>Welcome to Our TraceIT Service</h1>
    //         <span>Ensuring Transparency in Every Product's Journey</span>
    //       </div>
    //     </div>
    //     <div style={{  display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr 1fr', padding: '0px',margin:'inherit' }}>
    //       <FeatureCard
    //         heading="Real-Time Tracking"
    //         subHeading="Track your product's journey in real-time from production to delivery"
    //       />
    //       <FeatureCard
    //         heading="Product Authentication"
    //         subHeading="Verify the authenticity of your product with secure authentication features"
    //       />
    //       <FeatureCard
    //         heading="Supply Chain Transparency"
    //         subHeading="Gain insights into the supply chain process for increased transparency and trust"
    //       />
    //       <FeatureCard
    //         heading="Customizable Alerts"
    //         subHeading="Set up personalized alerts for specific events or milestones in the product journey"
    //       />
    //     </div>
    //     <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
    //       <h1>Trace Your Product's Path with Confidence</h1>
    //       <span>
    //         Our traceability service provides real-time tracking, product authentication, and supply chain transparency
    //         to empower consumers with credible information about the products they use. With our innovative technology,
    //         you can now follow your product's journey from origin to your hands.
    //       </span>
    //       <button style={{ marginTop: '20px' }}>Discover More</button>
    //     </div>
    //     <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px', padding: '20px' }}>
    //       <div style={{ maxWidth: '800px', textAlign: 'center' }}>
    //         <h2>FAQ</h2>
    //         <span>Here are some of the most common questions that we get.</span>
    //       </div>
    //     </div>
    //     <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
    //       <Question1
    //         question="How does the traceability service ensure product authenticity?"
    //         answer="Our service utilizes advanced technology such as unique identifiers and blockchain to verify the authenticity of products."
    //       />
    //       <Question1
    //         question="Can I track my product in real-time?"
    //         answer="Yes, our service provides real-time tracking capabilities so you can monitor your product's journey at any time."
    //       />
    //       <Question1
    //         question="How does the traceability service ensure transparency in the supply chain?"
    //         answer="We work closely with suppliers and partners to provide transparent information about the origins and handling of products throughout the supply chain."
    //       />
    //       <Question1
    //         question="Is my personal information secure when using the traceability service?"
    //         answer="Yes, we prioritize data security and use encryption protocols to safeguard your personal information."
    //       />
    //       <Question1
    //         question="What happens if I suspect a product is counterfeit?"
    //         answer="If you suspect a product is counterfeit, you can report it through our platform, and we will investigate the issue promptly."
    //       />
    //     </div>
    //   </div>
    // </div>

   
    <iframe src="/website.html" title="Website" style={{ width: '100%', height: '1000px' }} />
 
  );
};

export default Home;
