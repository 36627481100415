import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
// import { Link, useNavigate } from "react-router-dom";



function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });



//   const numbers = [
//     '6467', '7716', '7243', '2437', '7922', '9845', '6013', '1066', '4893', '6208', '5808', '6078',
//     '6767', '3078', '5323', '5055', '8722', '5744', '6440', '7290', '6377', '7777', '6435', '1298',
//     '2122', '1101', '2058', '4589', '9155', '9225', '4814', '3317', '5953', '8205', '6976', '2134',
//     '2419', '5831', '3079', '1083', '8750', '9528', '9580', '4883', '1979', '3040', '3839', '5102',
//     '5427', '7979', '2509', '8876', '7653', '8988', '6424', '8538', '7578', '5998', '7910', '3566',
//     '3801', '2723', '2224', '3786', '6980', '6131', '3458', '3453', '2520', '9842', '6140', '4498',
//     '4777', '4313', '3899', '9890', '4593', '3059', '2948', '2265', '8056', '2309', '2311', '4473',
//     '5453', '5206', '3249', '6081', '7260', '1968', '3737', '9671', '6329', '6044'
// ];

// const createDocuments = async () => {
//     try {
//         for (const number of numbers) {
//             // Make a POST request to create a document
//             await axios.post('https://65f7f037c097cdac9797.appwrite.global/', { key: number, id: number });
//             console.log(`Document with key ${number} created successfully`);
//         }
//         console.log('All documents created successfully');
//     } catch (error) {
//         console.error('Error creating documents:', error);
//     }
// };













  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle login authentication here
    console.log(formData);
    // Clear form data after submission
    setFormData({
      username: '',
      password: ''
    });
  };

  return (
    <div className="container">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} />
        </div>
        {/* <Link to="/ProcessingUnit"> */}
            {/* <Link to="/ProcessingUnit"> */}
              <button type='submit'>Login</button>
            {/* </Link> */}
      </form>
      {/* <button onClick={createDocuments}>Create list</button> */}
    </div>
  );
}

export default Login;
