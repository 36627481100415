import React from 'react';
import PropTypes from 'prop-types';

const Question1 = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '10px', // Adding padding
        margin: '10px 0', // Adding margins
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
          marginBottom: '5px', // Adding margin bottom for spacing between question and answer
        }}
      >
        {props.question}
      </span>
      <span>{props.answer}</span>
    </div>
  );
};

Question1.defaultProps = {
  answer:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus.',
  question: 'What types of cars do you sell?',
};

Question1.propTypes = {
  answer: PropTypes.string,
  question: PropTypes.string,
};

export default Question1;
