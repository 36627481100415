import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link} from "react-router-dom";
import "./navbar.css";



function Navbar() {
  // const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <header className="header">
      <div className="header__content">
        <Link to="/" className="header__content__logo">
          TraceIT
        </Link>
        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 768 ? `${"isMenu"}` : ""} 
          }`}
        >
          <ul>
            {/* <li>
              <Link to="/">Home</Link>
            </li> */}
            {/* <li>
              <Link to="/profile">Profile</Link>
            </li> */}
            {/* <li>
              <Link to="/Works">Browse Works</Link>
            </li> */}
            {/* <li>
              <Link to="/help">Help</Link>
            </li> */}

            {/* <Link to="/SignUp">
              <button className="btn">Register</button>
            </Link> */}
            {/* <Link to="/Login" className="decor"> */}
            {/* <Link to="/ProcessingUnit"> */}
              {/* <button className="btn btn__login">Login</button>
            </Link> */}
            {/* <Link to="/TraceFood" style={{marginLeft:'10px'}}>
      
              <button className="btn btn__login">TraceFood</button>
            </Link> */}
          </ul>
        </nav>
        {/* <div className="header__content__toggle">
          {!menuOpen ? (
            <MenuIcon onClick={menuToggleHandler} />
          ) : (
            <CloseIcon onClick={menuToggleHandler} />
          )}
        </div> */}
      </div>
    </header>
  );
}

export default Navbar;
