import React, { useState } from 'react';
import './ProcessingUnit.css';

function ProcessingUnit() {
  const [formData, setFormData] = useState({
    processName: '',
    unitName: '',
    unitOwner: '',
    contact: '',
    message: '',
    photo: null,
    location: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhotoChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      photo: e.target.files[0]
    }));
  };

  // const handleLocationCapture = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       position => {
  //         setFormData(prevState => ({
  //           ...prevState,
  //           location: {
  //             latitude: position.coords.latitude,
  //             longitude: position.coords.longitude
  //           }
  //         }));
  //       },
  //       error => {
  //         console.error('Error getting location:', error);
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // };
  const handleLocationCapture = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setFormData(prevState => ({
            ...prevState,
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          }));
        },
        error => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission here, for example, sending the data to a server
    console.log(formData);
    // Clear form data after submission
    setFormData({
      processName: '',
      message: '',
      photo: null,
      location: null
    });
  };

  return (
    <div className="container">
      <h1>Processing Unit</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="processName">Name of the Process:</label>
          <input type="text" id="processName" name="processName" value={formData.processName} onChange={handleChange} />
        </div>
        
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="photo">Add Photo:</label>
          <input type="file" id="photo" name="photo" accept="image/*" onChange={handlePhotoChange} />
        </div>
        <div className="form-group">
          <button type="button" onClick={handleLocationCapture}>Get Location</button>
          {formData.location && (
            <p>Location captured: Latitude {formData.location.latitude}, Longitude {formData.location.longitude}</p>
          )}
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ProcessingUnit;
