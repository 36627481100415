import React from 'react';
import { Chrono } from 'react-chrono';
import WorkIcon from '@mui/icons-material/Work';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalMallIcon from '@mui/icons-material/LocalMall';

function Contact() {
  const items = [
    {
      title: "Procurement from farmers",
      contentText: "Procuring rice from farmers.",
      icon: <LocalShippingIcon />,
      cardTitle: "Katarni Rice",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
    {
      title: "Cleaning of rice",
      contentText: "Cleaning the procured rice.",
      icon: <CleanHandsIcon />,
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
    {
      title: "Rice mill",
      contentText: "Processing rice in the mill.",
      icon: <WorkIcon />,
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
    {
      title: "Minor cleaning",
      contentText: "Additional cleaning process.",
      icon: <CleanHandsIcon />,
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
    {
      title: "Packaging",
      contentText: "Packaging the processed rice.",
      icon: <LocalShippingIcon />,
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
    {
      title: "Wholesalers",
      contentText: "Selling to wholesalers.",
      icon: <LocalMallIcon />,
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
    {
      title: "Retailers",
      contentText: "Selling to retailers.",
      icon: <LocalMallIcon />,
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText: "Men of the British Expeditionary Force (BEF) wade out to..",
    },
  ];

  return (
    <div style={{ width: '100%', height: '500px',paddingTop:'50px' }}>
      <Chrono items={items} mode="HORIZONTAL"  />
    </div>
  );
}

export default Contact;