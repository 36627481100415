import React, { useEffect, useState } from 'react';
import { Chrono } from 'react-chrono';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { useParams } from 'react-router-dom'; // Import useParams


function Home() {
  const { param1, param2 } = useParams();
  const [url, setUrl] = useState('');
  const [url1,setUrl1]= useState('')
  const [url2,setUrl2] = useState('')
  const [farm, setFarm] = useState('');
  const [farmLocation, setFarmLocation] = useState('');
  const [mill, setMill] = useState('');
  const [millLocation, setMillLocation] = useState('');
  const [comparisonDone, setComparisonDone] = useState(false); // State variable to track comparison status

  const handleParamChange = (param2) => {
    console.log('Parameter 2:', param2);
    // Set farm and mill based on param2
    switch (param2) {
      case '22c1rvdvq2':
        setFarm('Katarni farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Rajaun, Banka, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Katrani+Rice.pdf')
        setComparisonDone(true);
        break;
      case '5ycuzl5ftu':
        setFarm('Katarni farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Rajaun, Banka, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Katrani+Rice.pdf')
        setComparisonDone(true);
        break;
      case '1zulk2ze9m':
        setFarm('Katarni farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Rajaun, Banka, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Katrani+Rice.pdf')
        setComparisonDone(true);
        break;
      case 'vplbka26s0':
        setFarm('Katarni farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Rajaun, Banka, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Katrani+Rice.pdf')
        setComparisonDone(true);
        break;
      case 'nbpuo364im':
        setFarm('Katarni farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Rajaun, Banka, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Katrani+Rice.pdf')
        setComparisonDone(true);
        break;
      case 'sbxrhzmrc4':
        setFarm('Malbhog farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Goradih, Bhagalpur, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Mal+Bhog+Rice.pdf')
        setComparisonDone(true);
        break;
      case 'xpmc8sdpe3':
        setFarm('Malbhog farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Goradih, Bhagalpur, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Mal+Bhog+Rice.pdf')
        setComparisonDone(true);
        break;
      case 'hpl9oa0lgs':
        setFarm('Malbhog farm');
        setMill('Rice mill');
        setMillLocation('Mill Location: Aliganj, Bhagalpur, Bihar')
        setFarmLocation('Goradih, Bhagalpur, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Mal+Bhog+Rice.pdf')
        setComparisonDone(true);
        break;
      case '22c1rvdvq212':
        setFarm('Lemongrass farm');
        setMill('Lemongrass processing plant');
        setMillLocation('Processed at: Karjhaunsa, Katoria, Banka, Bihar')
        setFarmLocation('Banka, Bihar, Bihar')
        setUrl('https://traceitproducts.s3.ap-south-1.amazonaws.com/DeshajCare/Rice/Katrani+Rice.pdf')
        setComparisonDone(true);
        setUrl1('https://i.ibb.co/2YjxSG8/lemon-grass-oil-distillation-plant.jpg')
        setUrl2('https://i.ibb.co/x8X249g/Whats-App-Image-2024-07-17-at-03-55-45.jpg')
        break;
      default:
        setFarm('');
        setMill('');
        break;
    }
    // Set comparisonDone to true after comparison
  };

  // Call handleParamChange when the component mounts or when param2 changes
  useEffect(() => {
    handleParamChange(param2);
  }, [param2, param1]);

  const items = [
    {
      title: "21\njune\n2024",
      cardTitle: 'Deshaj Care',
      cardSubtitle:
        (
          <div style={{ padding: '7px' }}>
            Packaging Location: Dumariya, Koriya, Chandan. Contact: +91-8368028827 or write to us at contact@deshajlabs.org
          </div>
        ),
      media: {
        type: "IMAGE",
        source: {
          url: "https://i.ibb.co/tDHtNQK/packaging.jpg",
        },
      },
      items: [
        {
          title: "Download PDF",
          cardTitle: "Click here to verify product",
          cardSubtitle:
            (
              <div style={{ padding: '7px' }}>
                Now you can find out whether the product you bought is authentic or not.
              </div>
            ),
          icon: <LocalShippingIcon />,
          url: `/Productverification/${param2}`,
        },
        ...(!url1?[{
          title: "Download PDF",
          cardTitle: "Click here to get the Detailed Report",
          // cardSubtitle: "Presence of Residual Pesticides beyond Threshold Limit: No, <br>\nPresence of Heavy Metals beyond Threshold Limit: No",
          cardSubtitle: (
            <div style={{ padding: '7px' }}>
              Presence of Residual Pesticides beyond Threshold Limit: <span style={{ color: 'green' }}>No</span><br />
              Presence of Heavy Metals beyond Threshold Limit: <span style={{ color: 'green' }}>No</span>
            </div>
          ),
          icon: <LocalMallIcon />,
          url: url||'',
        }]:[]) ,
      ]
    },
    {
      title: "March\n2023",
      cardTitle: mill,
      cardSubtitle: millLocation,
      media: {
        type: "IMAGE",
        source: {
          url: (url1?url1:"https://i.ibb.co/9TDnqkX/Whats-App-Image-2024-03-17-at-7-36-47-PM.jpg"),
        },
      }
    },
    {
      title: "Dec\n2022",
      cardTitle: farm,
      cardSubtitle: farmLocation,
      media: {
        type: "IMAGE",
        source: {
          url: (url2?url2:"https://i.ibb.co/fqzBJTN/farmgate.jpg"),
        },
      }
    },
  ];

  return (
    <div style={{ paddingTop: '10px', backgroundColor: '#E0E8F3', paddingRight: '20px' }}>
      {comparisonDone && <Chrono items={items} mode="VERTICAL_ALTERNATING" verticalBreakPoint={720} slideShow timelinePointShape="diamond"
        timelinePointDimension={15} hideControls
        scrollable={{ scrollbar: false }}
        theme={{
          "primary": "#3f51b5",
          "secondary": "#ff9800",
          "titleColor": "#000",
          "titleColorActive": "#fff",
          "cardBgColor": "#ffffff",
          "cardTitleColor": "#000",
          "cardSubtitleColor": "#666",
          "cardMediaBgColor": "#f0f0f0",
          "cardDetailsBackGround": "#f5f5f5",
          "cardDetailsColor": "#333",
          "detailsColor": "#333",
          "iconBackgroundColor": "#3f51b5",
          "nestedCardBgColor": "#ffffff",
          "nestedCardTitleColor": "#000",
          "nestedCardSubtitleColor": "#666",
          "nestedCardDetailsBackGround": "#f5f5f5",
          "nestedCardDetailsColor": "#333"
        }}
      />}
    </div>
  );
}

export default Home;
