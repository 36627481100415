import React, { useState } from 'react';
import axios from 'axios';
import './Productverification.css';
import { useParams } from 'react-router-dom';

function Productverification() {
  const [key, setKey] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [verificationResult, setVerificationResult] = useState('');
  const { param2 } = useParams();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (key.trim() !== '') {
  //     try {
  //       const data = new FormData();
  //       data.append('key', key);
  //       data.append('qrid', param2);

  //       const response = await axios.post('https://www.traceit.co.in/api/product/verifyCode', data);

  //       const responseBody = response.data;
  //       console.log('response body :',responseBody);
  //       console.log('response :',responseBody.response);

  //       if (responseBody.message === 'verification Successful') {
  //         setVerificationResult('Success');
  //       } else if (responseBody.message === 'already verified') {
  //         setVerificationResult('Already verified');
  //       } else if(response.status === 422){
  //         setVerificationResult('Failure')
  //       }

  //       setShowPopup(true);
  //     } catch (error) {
  //       console.error('Error verifying product key:', error);
  //     }
  //   } else {
  //     console.log('Please enter a key');
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (key.trim() !== '') {
      try {
        const data = new FormData();
        data.append('key', key);
        data.append('qrid', param2);
  
        const response = await axios.post('https://www.traceit.co.in/api/product/verifyCode', data);
  
        const responseBody = response.data;
        console.log('response body :', responseBody);
        console.log('response :', responseBody.response);
  
        if (responseBody.message === 'verification Successful') {
          setVerificationResult('Success');
        } else if (responseBody.message === 'already verified') {
          setVerificationResult('Already verified');
        } else {
          setVerificationResult('Failure');
        }
  
        setShowPopup(true);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          setVerificationResult('Please enter a valid key');
        } else {
          console.error('Error verifying product key:', error);
          setVerificationResult('An unexpected error occurred');
        }
        setShowPopup(true);
      }
    } else {
      console.log('Please enter a key');
      setVerificationResult('Please enter a key');
      setShowPopup(true);
    }
  };
  

  const handleClosePopup = () => {
    setShowPopup(false);
    setVerificationResult('');
  };

  return (
    <div className="container">
      <h1>Verify Product Key</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="key">Product Key:</label>
          <input
            type="text"
            id="key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
        <p><strong>Note:</strong> The product Key will expire after one use.</p>
        <button type="submit">Verify</button>
      </form>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <p>Verification: {verificationResult}</p>

            {verificationResult === 'Please enter a valid key' && (
              <div className="contact-us">
                <h2>Failed Verification</h2>
                <p>We're sorry for the inconvenience. Please feel free to contact us for further assistance.</p>
                <p>Contact Information:</p>
                <ul>
                  <li>Email: contact@deshajlabs.org</li>
                  <li>Phone: +91  83680 28827</li>
                </ul>
              </div>
            )}
            {verificationResult === 'Already verified' && (
              <div className="contact-us">
                <h2>This Product Has Already Been Verified</h2>
                <p>We're sorry for any inconvenience. Please feel free to contact us for further assistance.</p>
                <p>Contact Information:</p>
                <ul>
                  <li>Email: contact@deshajlabs.org</li>
                  <li>Phone: +91  83680 28827</li>
                </ul>
              </div>
            )}
            {verificationResult === 'Success' && (
              <div className="contact-us">
                <h2>Verification Successful</h2>
                <p>The product key has been successfully verified. Thank you for verifying your product.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Productverification;
