// import React from 'react';
// import {APIProvider, Map} from '@vis.gl/react-google-maps';
// import MapWithArrows from '../MapWithArrows';




// const TraceFood = () => (


  
// //    <div>
// //     <MapWithArrows/>
// //    </div>
//   <APIProvider apiKey={'AIzaSyDfsoTGy51IpSRi6o2KuvK4_eEFGzL6CA0'}>
//     <Map
//       style={{width: '100vw', height: '100vh'}}
//       defaultCenter={{lat: 22.54992, lng: 0}}
//       defaultZoom={3}
//       gestureHandling={'greedy'}
//       disableDefaultUI={true}
//     />
//   </APIProvider>
// );

// export default TraceFood




// const TraceFood = () => {
//   const center = { lat: 40.7128, lng: -74.0060 }; // New York City
//   const locations = [
//     { lat: 41.8781, lng: -87.6298 }, // Chicago
//     { lat: 34.0522, lng: -118.2437 } // Los Angeles
//     // Add more locations as needed
//   ];

//   return (
//     <div>
//       <h1>Map with Arrows</h1>
//       <MapWithArrows center={center} locations={locations} />
//     </div>
//   );
// };





// import React, {useMemo, useEffect} from 'react';
// import {APIProvider, Map, useMap} from '@vis.gl/react-google-maps';
// import {DeckProps} from '@deck.gl/core';
// import {ScatterplotLayer} from '@deck.gl/layers';
// import {GoogleMapsOverlay} from '@deck.gl/google-maps';

// function DeckGLOverlay() {
//   const map = useMap();
//   const overlay = useMemo(() => new GoogleMapsOverlay(props));

//   useEffect(() => {
//     overlay.setMap(map);
//     return () => overlay.setMap(null);
//   }, [map])

//   overlay.setProps(props);
//   return null;
// }

// function TraceFood() {
//   const layers = [
//     new ScatterplotLayer({
//       id: 'deckgl-circle',
//       data: [
//         {position: [0.45, 51.47]}
//       ],
//       getPosition: d => d.position,
//       getFillColor: [255, 0, 0, 100],
//       getRadius: 1000
//     })
//   ];

//   return <APIProvider apiKey="AIzaSyDfsoTGy51IpSRi6o2KuvK4_eEFGzL6CA0">
//     <Map
//       defaultCenter={{lat: 51.47, lng: 0.45}}
//       defaultZoom={11}
//       mapId="maplayerwithdatavis" >
//       <DeckGLOverlay layers={layers} />
//     </Map>
//   </APIProvider>;
// }

// export default TraceFood;
import React, { useMemo, useEffect, useState } from 'react';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import { ScatterplotLayer } from '@deck.gl/layers';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';

function DeckGLOverlay(props) {
  const map = useMap();
  const overlay = useMemo(() => new GoogleMapsOverlay(props));

  useEffect(() => {
    overlay.setMap(map);
    return () => overlay.setMap(null);
  }, [map,overlay])

  // const overlayStyle = {
  //   zIndex: 1000 // Set a higher z-index value as needed
  // };

  return (
    <div >
      {overlay} {/* Render the overlay */}
    </div>
  );
}

function TraceFood() {
  const [mapLoaded, setMapLoaded] = useState(false); // State to track map loading

  // Dummy data for ScatterplotLayer
  const dummyData = [
    { position: [77.2090, 28.6139] }, // New Delhi, India
    { position: [77.5946, 12.9716] }, // Bangalore, India
    { position: [72.8777, 19.0760] }  // Mumbai, India
    // Add more dummy data points as needed
  ];

  const layers = [
    new ScatterplotLayer({
      id: 'deckgl-circle',
      data: dummyData, // Use dummy data
      getPosition: d => d.position,
      getFillColor: [255, 0, 0, 100],
      getRadius: 10000
    })
  ];

  return (
    <APIProvider apiKey="AIzaSyDfsoTGy51IpSRi6o2KuvK4_eEFGzL6CA0">
      <Map
      style={{width: '100vw', height: '100vh'}}
      defaultCenter={{ lat: 28.6139, lng: 77.2090 }}
        defaultZoom={5}
        gestureHandling={'greedy'}
        onLoad={() => setMapLoaded(true)} // Callback to set mapLoaded state when map is loaded
        disableDefaultUI={true}
      >
        {mapLoaded && <DeckGLOverlay layers={layers} />} {/* Render DeckGLOverlay only after map is loaded */}
      </Map>


    </APIProvider>
  );
}



export default TraceFood;
