

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Home2 from './components/Home2';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/Navbar/Navbar';
import ProcessingUnit from './components/ProcessingUnit/ProcessingUnit';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Productverification from './components/Productverification/Productverification';
import TraceFood from './components/TraceFood/TraceFood';


function App() {
  return (
    <Router>
      <div>
      
        <Navbar></Navbar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:param1/:param2" element={<Home2/>} />
          <Route path="/ProcessingUnit" element={<ProcessingUnit/>} />
          <Route path="/Login" element={<Login/>} />
          <Route path="/TraceFood" element={<TraceFood/>} />
          <Route path="/SignUp" element={<SignUp/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Productverification/:param2" element={<Productverification />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

